export const environment = {
  production: false,
  projectName: "DTSDemo",
  dev: {
    REST_API_SERVER: "https://dts.demo.api.testctsolution.it/api"
  },
  variables: {
    defaults: {}
  },
  customTranslationsDirectory: '/assets/custom-translations',
  defaultLang: 'it',
  projectBaseDir: 'dts'
};
